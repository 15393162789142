<template>
  <v-snackbar
      :color="message.color"
      v-model="message.show"
      top
  >
    {{ message.text }}

    <template v-slot:action="{ attrs }">
      <v-btn
          color="quinary"
          text
          v-bind="attrs"
          @click="$emit('closeAlert')"
      >
        Schließen
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "Message",
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    message: {
      type: Object,
      default(){
        return {}
      }
    },
  },
}
</script>
